import { z } from "zod";
import { BlockIdPlaceholder } from "utils/regex";
import { BlockBaseSchema } from "block-system/types/base";
import { blockType } from "./constants";
import { WidthSchema } from "../__shared__/components/WidthField/schema";
import { HorizontalAlignmentSchema } from "../__shared__/components/AlignmentField/schema";

export const MarkdownTextBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: z.object({
    id: z.string().cuid().or(z.string().regex(BlockIdPlaceholder)).optional(),
    content: z.string(),
    textAlign: z.enum(["left", "center"]),
    openLinksInSameTab: z.boolean().optional(),
    style: z
      .object({
        width: WidthSchema.optional(),
        alignment: HorizontalAlignmentSchema.optional(),
      })
      .optional(),
  }),
}).openapi({ ref: "MarkdownTextBlock" });
