import { BlockType } from "@prisma/client";

export const blockType = "stripe-payment-block";
export const blockTypeDbName = BlockType.StripePayment;

export const blockTypeToName = {
  [blockType]: blockTypeDbName,
} as const;

export const blockNameToType = {
  [blockTypeDbName]: blockType,
} as const;

/**
 * See https://docs.stripe.com/currencies#minimum-and-maximum-charge-amounts
 * for maximum value.
 *
 * Total = value * the biggest possible quantity
 */
export const MAXIMUM_VALUE_TOTAL_CENTS = 99999999 * 100;
export const MAXIMUM_VALUE_TOTAL_NOT_CENTS = MAXIMUM_VALUE_TOTAL_CENTS / 100;
