import { z } from "zod";

export const HorizontalAlignmentSchema = z.union([
  z.literal("left"),
  z.literal("center"),
  z.literal("right"),
]);

export type HorizontalAlignment = z.infer<typeof HorizontalAlignmentSchema>;

export const VerticalAlignmentSchema = z.union([
  z.literal("top"),
  z.literal("center"),
  z.literal("bottom"),
]);

export type VerticalAlignment = z.infer<typeof VerticalAlignmentSchema>;

export type Alignment = {
  horizontal: HorizontalAlignment;
  vertical: VerticalAlignment;
};
