import { isNumber } from "lodash";
import { Spinner as ZSpinner } from "@zapier/design-system";
import { styled } from "lib/theme";

const Wrapper = styled.div<{
  $height: string;
}>`
  height: ${({ $height }) => $height};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export type Props = Partial<React.ComponentProps<typeof ZSpinner>> & {
  className?: string;
  height?: number | string;
  centered?: boolean;
  "data-testid"?: string;
};

export function Spinner({
  className,
  height = 0,
  "data-testid": dataTestId = "editor-spinner",
  ...props
}: Props) {
  const parsedHeight = isNumber(height) ? `${height}px` : height;

  return (
    <Wrapper
      className={className}
      $height={parsedHeight}
      data-testid={dataTestId}
    >
      <ZSpinner {...props} />
    </Wrapper>
  );
}
