export const HOSTNAME_REGEX =
  /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/;

export const OTP_REGEX = /^[\d]{6}$/;

export const URL_PATTERN_REGEX =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;

export const EMAIL_PATTERN_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const InterfaceIdPlaceholder = /^_INTERFACE_(\d+)/;
export const PageIdPlaceholder = /^_PAGE_(\d+)$/;
export const BlockIdPlaceholder = /^_BLOCK_(\d+)$/;
export const ActionIdPlaceholder = /^_ACTION_(\d+)$/;
export const TableIdPlaceholder = /^_TABLE_(\d+)$/;
export const FieldIdPlaceholder = /^_FIELD_(\d+)$/;
export const ZapIdPlaceholder = /^_ZAP_(\d+)$/;
export const ZapStepIdPlaceholder = /^_GEN_(\d+)$/;
export const ChatbotIdPlaceholder = /^_CHATBOT_(\d+)/;

export const ALPHANUMERIC_DASHES_SPACES_REGEX = /^[a-zA-Z0-9\s-]*$/;

export const PAGE_SLUG_REGEX = /^[a-z0-9](?:[a-z0-9\-]{0,30}[a-z0-9])?$/;

export const PROJECT_SLUG_REGEX = /^[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?$/;

// NOTE: This is not the intended project slug validation, but it's a reflection of the current reality.
// TODO: Remove this as part of INTRFCS-2858
export const PROJECT_SLUG_CASE_INSENSITIVE_REGEX = new RegExp(
  PROJECT_SLUG_REGEX,
  "i"
);

export const COLOR_HEX_REGEX = /^#(?:[0-9A-F]{3,4}|[0-9A-F]{6}|[0-9A-F]{8})$/i;
export const COLOR_HEX_OR_RGB_REGEX =
  /^#(?:[0-9A-F]{3,4}|[0-9A-F]{6}|[0-9A-F]{8})$|^(?:rgb)\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*\)$|^(?:rgba)\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*(?:,\s*(?:0(?:\.\d{1,3})?|1(?:\.0{1,3})?))?\s*\)$/i;

export const GOOGLE_ANALYTICS_TRACKING_ID_REGEX =
  /^[A-Z][A-Z0-9]?-[A-Z0-9]{4,10}(?:\-[1-9]\d{0,3})?$/i;

/** Known safe URL protocols */
// **IMPORTANT** if capture groups are changed, update matchSafeUrl indices as well
export const SAFE_URL_PATTERN =
  /^(?:(https?|mailto|ftp|tel|file|sms):|([^&:/?#]*)(?:[/?#]|$))/i;

export const matchSafeUrl = (url: string) => {
  const match = url.match(SAFE_URL_PATTERN);
  return {
    isMatch: !!match,

    // named capture groups would be nicer, but older browsers from ~2 years ago don't support it
    // not sure what our browser version cutoff is, but we should probably play it safe here
    hasProtocol: !!match?.[1]?.length,
    hasDomainWithoutProtocol: !!match?.[2]?.length,
  };
};

/** Match only image, video, and audio types under the data: protocol */
export const DATA_URL_PATTERN =
  /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i;

/** Pulled from https://stackoverflow.com/a/71006865 */
export const YOUTUBE_URL_REGEX =
  /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/;

export const VIMEO_URL_REGEX = /vimeo\.com\/(\d+)/;
export const LOOM_URL_REGEX = /loom\.com\/share\/([a-zA-Z0-9]+)/;
export const ZAPPY_VIDEO_URL_REGEX = /cdn\.zappy\.app\/[0-9a-f]{32}\.mp4/;

/** Extract a uuid from the Uploadcare file URL; Example: https://ucarecdn.com/d8f9dfaf-417f-439b-99ef-bfbc5d1b6e7f/ */
export const UPLOADCARE_UUID_REGEX = /https:\/\/ucarecdn.com\/(.+)\/$/;

export const NEW_LINES_REGEX = /\r?\n|\r/g;

export const TEMPLATE_PARAM_REGEX = /\$\{\{ *([A-Za-z0-9_\.]+) *\}\}/g;

export const CSP_HOST_REGEX =
  /^(\*\.){0,1}([a-zA-Z0-9\-]+\.){1,}[a-zA-Z0-9\-]+(:[0-9]{1,5}){0,1}$/;
