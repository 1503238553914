import "zod-openapi/extend";

import { z } from "zod";
import { BlockIdPlaceholder } from "utils/regex";
import { BlockBaseSchema } from "block-system/types/base";
import { blockType } from "./constants";
import { WidthSchema } from "../__shared__/components/WidthField/schema";

export const DividerBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: z.object({
    id: z.string().cuid().or(z.string().regex(BlockIdPlaceholder)).optional(),
    spacing: z.enum(["small", "medium", "large"]).optional(),
    width: WidthSchema.optional(),
  }),
}).openapi({ ref: "DividerBlock" });
