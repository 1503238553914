import "zod-openapi/extend";

import {
  BlockBaseSchema,
  BlockIdSchema,
  ComponentBlockStyleSchema,
} from "block-system/types/base";
import { z } from "zod";
import { blockType } from "./constants";

export const MediaType = z.enum(["image", "video", "logo"]);
export const MediaSource = z.enum(["url", "upload"]);
export const MediaAlignment = z.enum(["left", "center", "right"]);

export const MediaBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: z.object({
    id: BlockIdSchema.optional(),
    mediaType: MediaType.default("image").openapi({ effectType: "input" }),
    mediaSource: MediaSource.default("url").openapi({ effectType: "input" }),
    mediaUrl: z.string().optional(),
    height: z.coerce
      .number()
      .max(1000)
      .optional()
      .default(200)
      .openapi({ effectType: "input" }),
    alignContent: MediaAlignment.default("center").openapi({
      effectType: "input",
    }),
    style: ComponentBlockStyleSchema.optional(),
    alt: z.string().optional(),
  }),
}).openapi({ ref: "MediaBlock" });
