import "zod-openapi/extend";

import { BlockBaseSchema, BlockIdSchema } from "block-system/types/base";
import { z } from "zod";
import { blockType } from "./constants";
import { validateLink } from "server/schemas/util";
import { TEMPLATE_PARAM_REGEX } from "utils/regex";

export const LinkCardSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: z.object({
    id: BlockIdSchema.optional(),
    title: z.string(),
    description: z.string().optional(),
    linkText: z.string(),
    linkType: z
      .enum(["page", "url"])
      .default("url")
      .optional()
      .openapi({ effectType: "input" }),
    pageId: z.string().optional(),
    url: z.string().refine(
      (value) => {
        // Skip this validation if the value is a template parameter. It should be
        // validated again after the template execution completes.
        if (value.match(TEMPLATE_PARAM_REGEX)) {
          return true;
        }
        return validateLink(value);
      },
      {
        message: "Not a valid link address",
      }
    ),
    emoji: z.string().optional(),
    openInNewTab: z
      .boolean()
      .default(false)
      .optional()
      .openapi({ effectType: "input" }),
  }),
}).openapi({ ref: "LinkCardBlock" });
