import "zod-openapi/extend";

import { z } from "zod";
import { BlockIdPlaceholder } from "utils/regex";
import {
  BlockBaseSchema,
  ComponentBlockStyleSchema,
  FilterSchema,
} from "block-system/types/base";
import { blockType } from "./constants";
import { strings } from "./strings";
import { RecordOrderSchema } from "server/schemas/table-records";

export const ChecklistBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: z.object({
    id: z.string().cuid().or(z.string().regex(BlockIdPlaceholder)).optional(),
    tableId: z.string().optional(),

    title: z
      .string()
      .default(strings.DEFAULT_CHECKLIST_TITLE_COPY)
      .openapi({ effectType: "input" }),

    newListItemButtonCopy: z
      .string()
      .default(strings.NEW_LIST_ITEM_BUTTON_DEFAULT_COPY)
      .openapi({ effectType: "input" }),

    listItemLabelFieldId: z.number().optional(),
    listItemCompletedFieldId: z.number().optional(),
    listItemTitleFieldId: z.number().optional(),
    listItemDueDateFieldId: z.number().optional(),

    listItemDetailFields: z
      .array(z.number())
      .default([])
      .openapi({ effectType: "input" }),

    filters: z.array(FilterSchema).default([]).openapi({ effectType: "input" }),

    orders: z
      .array(RecordOrderSchema)
      .default([])
      .openapi({ effectType: "input" }),

    canCreateListItems: z
      .boolean()
      .default(false)
      .openapi({ effectType: "input" }),

    canEditListItems: z
      .boolean()
      .default(true)
      .openapi({ effectType: "input" }),

    canDeleteListItems: z
      .boolean()
      .default(false)
      .openapi({ effectType: "input" }),

    canSearchListItems: z
      .boolean()
      .default(false)
      .openapi({ effectType: "input" }),

    canViewDetails: z.boolean().default(true).openapi({ effectType: "input" }),

    canViewCompleted: z
      .boolean()
      .default(true)
      .openapi({ effectType: "input" }),
    /**
     * Problem: I want to provide the defaults and ensure TypeScript marks the `style` fields as non-optional.
     * Attempted: I tried to use `.default` on the schema, but that did not remove the optionality of the fields.
     * Solution: I used `.transform` & `.default`.
     */
    style: ComponentBlockStyleSchema.default({
      width: "wide",
      alignment: "center",
    })
      .openapi({ effectType: "input" })
      .transform((style) => ({
        width: style.width ?? "wide",
        alignment: style.alignment ?? "center",
      }))
      .openapi({ effectType: "input" }),
  }),
}).openapi({ ref: "ChecklistBlock" });
