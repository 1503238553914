import "zod-openapi/extend";

import { z } from "zod";
import {
  BlockBaseSchema,
  ComponentBlockStyleSchema,
} from "block-system/types/base";
import { blockType } from "./constants";
import { BlockIdPlaceholder, COLOR_HEX_REGEX } from "utils/regex";
import {
  NavigateActionSchema,
  OpenExternalUrlActionSchema,
  NotificationActionSchema,
} from "block-system/types/common";
import { ZapOutputSchema } from "server/schemas/zaps";
// Importing from types/index above causes as cyclic dependency issue in tests

export const BUTTON_SIZE_STYLES = {
  small: {
    height: `h-[30px]`,
    padding: "px-[14px] py-[5px]",
  },
  medium: {
    height: `h-[44px]`,
    padding: "px-[20px] py-[10px]",
  },
  large: {
    height: `h-[60px]`,
    padding: "px-[20px] py-[10px]",
  },
} as const;

export const BUTTON_WIDTH_STYLES = {
  fitContent: "w-auto",
  fill: "w-full",
} as const;

export const ButtonActionSchema = z.discriminatedUnion("type", [
  NavigateActionSchema,
  OpenExternalUrlActionSchema,
  NotificationActionSchema,
  z.object({
    type: z.literal("zap"),
    config: z.object({
      zap: ZapOutputSchema.pick({
        id: true,
        title: true,
        paused: true,
      }),
    }),
  }),
]);

export const ButtonActionsSchema = ButtonActionSchema.array()
  .max(2)
  .superRefine((actions, ctx) => {
    const hasZapAction = actions.some((action) => action.type === "zap");
    const hasNotificationAction = actions.some(
      (action) => action.type === "notification"
    );

    if (actions.length > 1 && hasNotificationAction && !hasZapAction) {
      return ctx.addIssue({
        message: "Notification action is not allowed without a Zap action",
        code: z.ZodIssueCode.custom,
      });
    } else if (actions.length > 1 && !hasNotificationAction) {
      return ctx.addIssue({
        message: "Only one action is allowed",
        code: z.ZodIssueCode.custom,
      });
    }
    return actions;
  });

export type ButtonAction = z.infer<typeof ButtonActionSchema>;
export type ButtonActions = z.infer<typeof ButtonActionsSchema>;
export type AllowedButtonActionType = ButtonAction["type"];

export const ButtonBlockConfigSchema = z.object({
  id: z.string().cuid().or(z.string().regex(BlockIdPlaceholder)).optional(),
  label: z.string().min(1).max(50, "Label must be less than 50 characters"),
  type: z
    .enum(["primary", "secondary"])
    .default("primary")
    .openapi({ effectType: "input" }),
  size: z
    .enum(["small", "medium", "large"])
    .default("medium")
    .openapi({ effectType: "input" }),
  colorType: z
    .enum(["theme", "custom"])
    .default("theme")
    .openapi({ effectType: "input" }),
  customColor: z
    .object({
      background: z
        .string()
        .regex(COLOR_HEX_REGEX, "Invalid hex color code")
        .default("#000000")
        .openapi({ effectType: "input" }),
      textColor: z
        .string()
        .regex(COLOR_HEX_REGEX, "Invalid hex color code")
        .default("#ffffff")
        .openapi({ effectType: "input" }),
    })
    .optional(),
  width: z
    .enum(["fitContent", "fill"])
    .default("fitContent")
    .openapi({ effectType: "input" }),
  alignment: z
    .enum(["left", "center", "right"])
    .default("center")
    .openapi({ effectType: "input" }),
  actions: ButtonActionsSchema.default([]).openapi({ effectType: "input" }),
  style: ComponentBlockStyleSchema.optional(),
});

export const ButtonBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: ButtonBlockConfigSchema,
}).openapi({ ref: "ButtonBlock" });
