import "zod-openapi/extend";

import { z } from "zod";
import {
  BlockBaseSchema,
  BlockIdSchema,
  ComponentBlockStyleSchema,
  FilterSchema,
} from "block-system/types/base";
import { RecordOrderSchema } from "server/schemas/table-records";

const APITableSchema = z.object({ id: z.string() });

export const TableBlockSchema = BlockBaseSchema.extend({
  type: z.literal("table-listing-block"),
  config: z.object({
    id: BlockIdSchema.optional(),
    title: z.string().optional(),
    tableId: APITableSchema.shape.id.optional(),
    hiddenFields: z.array(z.string()).optional(),
    columnOrder: z.array(z.number().or(z.string())).optional(),
    canCreateRecords: z
      .boolean()
      .default(false)
      .openapi({ effectType: "input" }),
    newRecordButtonCopy: z.string().optional(),
    canUpdateRecords: z
      .boolean()
      .default(false)
      .openapi({ effectType: "input" }),
    canDeleteRecords: z
      .boolean()
      .default(false)
      .openapi({ effectType: "input" }),
    canSearchRecords: z
      .boolean()
      .default(true)
      .openapi({ effectType: "input" }),
    style: ComponentBlockStyleSchema.optional(),
    filters: z.array(FilterSchema).optional(),
    orders: z
      .array(RecordOrderSchema)
      .optional()
      .openapi({ effectType: "input" }),
  }),
}).openapi({ ref: "TableBlock" });
