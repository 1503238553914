import "zod-openapi/extend";

import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { BlockConfigErrorSchema } from "./errors";
import {
  ActionIdPlaceholder,
  BlockIdPlaceholder,
  TEMPLATE_PARAM_REGEX,
} from "utils/regex";
import { FilterOperatorSchema } from "server/schemas/table-records";

extendZodWithOpenApi(z);

export const ActionIdPlaceholderSchema = z.string().regex(ActionIdPlaceholder);

export const BlockBaseSchema = z
  .object({
    errors: z.array(BlockConfigErrorSchema).optional(),
  })
  .openapi({ ref: "InterfaceBlockBase" });

export const BlockIdSchema = z
  .string()
  .cuid()
  .or(z.string().regex(BlockIdPlaceholder));

export const ComponentBlockStyleSchema = z.object({
  width: z
    .union([
      z.literal("full"),
      z.literal("wide"),
      z.literal("medium"),
      z.literal("narrow"),
    ])
    .optional(),
  alignment: z
    .union([z.literal("left"), z.literal("center"), z.literal("right")])
    .optional(),
});

export type ComponentBlockStyle = z.infer<typeof ComponentBlockStyleSchema>;

export const FilterConditionSchema = z.object({
  fieldId: z.number().or(z.string().regex(TEMPLATE_PARAM_REGEX)).or(z.string()),
  operator: FilterOperatorSchema.or(z.literal("currentUser")),
  value: z.union([
    z.string().min(1, "Value is required"),
    z.number(),
    z.boolean(),
    z.array(z.string().min(1, "Value is required")),
  ]),
});

export type FilterCondition = z.infer<typeof FilterConditionSchema>;

export const FilterSchema = z.object({
  conditions: z.array(FilterConditionSchema),
});

export type FieldFilter = z.infer<typeof FilterSchema>;

/**
 * See https://zapierorg.atlassian.net/browse/INTRFCS-3135 for more information.
 */
export const MAX_FILTERS_COUNT = 3;
