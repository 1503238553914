import { z } from "zod";

export const BlockConfigErrorCodeSchema = z.enum([
  "form:table-does-not-exist",
  "form:missing-storage-id",
  "form:table-missing-add-records-permission",
  "form:table-missing-add-fields-permission",
  "form:table-missing-update-fields-permission",
  "form:table-missing-delete-delete-permission",
  "form:conditional-logic-invalid-field-id",
  "form:conditional-logic-invalid-value",
  "kanban:table-does-not-exist",
  "kanban:missing-table-id",
  "kanban:group-field-does-not-exist",
  "kanban:group-field-invalid",
  "kanban:title-field-does-not-exist",
  "kanban:title-field-invalid",
  "kanban:description-field-does-not-exist",
  "kanban:description-field-invalid",
  "kanban:no-selected-columns",
  "kanban:empty-filter",
  "kanban:maximum-number-of-filters-exceeded",
  "kanban:filter-condition-field-invalid",
  "kanban:filter-condition-operator-invalid",
  "kanban:filter-condition-value-invalid",
  "kanban:table-missing-add-records-permission",
  "kanban:table-missing-update-records-permission",
  "kanban:table-missing-delete-records-permission",
  "kanban:order-direction-invalid",
  "kanban:order-field-does-not-exist",
  "kanban:order-field-invalid",
  "table-listing:missing-table-id",
  "table-listing:table-does-not-exist",
  "table-listing:empty-filter",
  "table-listing:maximum-number-of-filters-exceeded",
  "table-listing:filter-condition-field-invalid",
  "table-listing:filter-condition-operator-invalid",
  "table-listing:filter-condition-value-invalid",
  "table-listing:table-missing-add-records-permission",
  "table-listing:table-missing-update-records-permission",
  "table-listing:table-missing-delete-records-permission",
  "chatbot:authentication-does-not-exist",
  "chatbot:authentication-test-failed",
  "chatbot:model-not-available",
  "chatbot:max-tokens-too-high",
  "chatbot:chatbot-does-not-exist",
  "layout:child-not-found",

  "checklist:missing-table-id",
  "checklist:table-does-not-exist",
  "checklist:missing-list-item-completed-field",
  "checklist:missing-list-item-title-field",
  "checklist:missing-list-item-due-date-field",
  "checklist:missing-list-item-label-field",
  "checklist:list-item-detail-fields-out-of-sync",
  "checklist:table-missing-add-records-permission",
  "checklist:table-missing-update-records-permission",
  "checklist:table-missing-delete-records-permission",
  "checklist:empty-filter",
  "checklist:filter-condition-field-invalid",
  "checklist:filter-condition-operator-invalid",
  "checklist:filter-condition-value-invalid",
]);

export type BlockConfigErrorCode = z.infer<typeof BlockConfigErrorCodeSchema>;

export const BlockConfigErrorSchema = z.object({
  code: BlockConfigErrorCodeSchema,
  blockId: z.string().nullable(),
  path: z.array(z.union([z.string(), z.number()])),
});

export type BlockConfigError = z.infer<typeof BlockConfigErrorSchema>;
