export const strings = {
  NAME: "Checklist",

  DESCRIPTION:
    "Create and assign tasks from a Table, and trigger when a task is completed.",

  DEFAULT_CHECKLIST_TITLE_COPY: "My checklist",

  NEW_LIST_ITEM_BUTTON_DEFAULT_COPY: "New checklist item",
} as const;
