import { z } from "zod";

export const FilterOperatorSchema = z.union([
  z.literal("exact"),
  z.literal("contains"),
  z.literal("icontains"),
  z.literal("in"),
  z.literal("startswith"),
  z.literal("gte"),
  z.literal("gt"),
  z.literal("lte"),
  z.literal("lt"),
  z.literal("range"),
]);

export type FilterConditionOperators = z.infer<typeof FilterOperatorSchema>;

export const RecordFilterSchema = z.object({
  key: z.string(),
  operator: FilterOperatorSchema.optional(),
  value: z.string(),
});

export const RecordOrderSchema = z.object({
  key: z.string().optional(),
  direction: z.union([z.literal("asc"), z.literal("desc")]).optional(),
});

const RecordQuerySchema = z.object({
  filters: RecordFilterSchema.array().optional(),
  orders: RecordOrderSchema.array().optional(),
  cursor_query: z
    .discriminatedUnion("query_type", [
      z.object({
        query_type: z.literal("window"),
        start_cursor: z.string().optional(),
        end_cursor: z.string().optional(),
      }),
      z.object({
        query_type: z.literal("before"),
        before_cursor: z.string().optional(),
      }),
    ])
    .optional(),
  limit: z.number().int().optional(),
});

export const listRecordsSchema = z.object({
  tableId: z.string(),
  filter: RecordQuerySchema,
  cursor: RecordQuerySchema.shape.cursor_query,
  fields: z.string().array().optional(),
});

export type RecordFilter = z.infer<typeof RecordFilterSchema>;
export type RecordOrder = z.infer<typeof RecordOrderSchema>;

export type RecordQuery = z.infer<typeof RecordQuerySchema>;
export type CursorQuery = z.infer<typeof RecordQuerySchema.shape.cursor_query>;

export const getRecordSchema = z.object({
  tableId: z.string(),
  recordId: z.string(),
  fields: z.string().array().optional(),
});

export const createRecordsSchema = z.object({
  tableId: z.string(),
  body: z.object({
    new_records: z
      .object({
        data: z.record(z.unknown()),
      })
      .array(),
    change_options: z.record(z.unknown()).optional(),
  }),
});

export const tableRecordSchema = z.object({
  data: z.record(z.string(), z.unknown()),
  id: z.string(),
});

export type CreateRecordsBody = z.infer<typeof createRecordsSchema>["body"];

export const updateRecordsSchema = z.object({
  tableId: z.string(),
  body: z.object({
    updated_records: z
      .object({
        id: z.string(),
        data: z.record(z.unknown()),
      })
      .array(),
    change_options: z.record(z.unknown()).optional(),
  }),
  fields: z.string().array().optional(),
});

export type UpdateRecordsBody = z.infer<typeof updateRecordsSchema>["body"];

export const deleteRecordsSchema = z.object({
  tableId: z.string(),
  body: z.object({
    record_ids: z.string().array(),
    change_options: z.any(),
  }),
});

export type DeleteRecordsBody = z.infer<typeof deleteRecordsSchema>["body"];

export const deliverRecordChangesSchema = z.object({
  recordId: z.string(),
  tableId: z.string(),
});
