import { z } from "zod";

export const WidthSchema = z.union([
  z.literal("narrow"),
  z.literal("medium"),
  z.literal("wide"),
  z.literal("full"),
]);

export type Width = z.infer<typeof WidthSchema>;
