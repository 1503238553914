import { BlockType } from "@prisma/client";

export const blockType = "checklist-block";
export const blockTypeDbName = BlockType.Checklist;

export const blockTypeToName = {
  [blockType]: blockTypeDbName,
} as const;

export const blockNameToType = {
  [blockTypeDbName]: blockType,
} as const;
