import { z, ZodSchema } from "zod";
import { toASCII } from "punycode";
import { DATA_URL_PATTERN, matchSafeUrl } from "utils/regex";

export const infiniteQueryOutput = (itemSchema: ZodSchema) => {
  return z.object({
    items: z.array(itemSchema),
    nextCursor: z.string().optional(),
    count: z.number(),
  });
};

export const validateEmail = (value: string): boolean => {
  // Based on Django for compatibility with monolith: https://github.com/django/django/blob/9d756afb07de8ef6e4d1980413979496643f1c3b/django/core/validators.py#L174-L250
  const userRegex =
    /**
     * TypeScript started complaining about this regular expression when upgraded to 5.5.2.
     */
    // @ts-ignore
    /(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*$|^\"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-\011\013\014\016-\177])*\"$)/i;

  if (!value || !value.includes("@")) {
    return false;
  }

  const parts = value.split("@");

  const userPart = parts[0];
  const domainPart = parts[1];

  if (!userRegex.test(userPart)) {
    return false;
  }

  if (!validateDomainPart(domainPart)) {
    const punycodeDomainPart = toASCII(domainPart);
    if (!validateDomainPart(punycodeDomainPart)) {
      return false;
    }
  }

  return true;
};

export const validateDomainPart = (domainPart: string): boolean => {
  // domain name + an arbitrary number of subdomains can be a max length of 63, which
  // includes a leading character and a trailing character that cannot be a dash
  // TLD must be a min length of 2 and a max of 63, but may not end with a dash
  // Examples of invalid domains: -zapier.com, zapier-.com, zapier.com-, zapier.c
  const domainRegex =
    /^((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+)(?:[A-Z0-9-]{1,62}[A-Z0-9])$/i;

  const literalRegex = /\[([A-F0-9:.]+)\]$/i;
  if (domainRegex.test(domainPart)) {
    return true;
  }

  // This differs from Django in two ways:
  // 1. There is no exact equivalent of re.match in JS: https://docs.python.org/3/library/re.html#re.match
  // 2. We fallback to Zod validation for IP addresses and assume it matches Python core (which is what Django uses)
  const literalMatch = domainPart.match(literalRegex);
  if (literalMatch) {
    const ipAddress = literalMatch[0];
    const parseResult = z.string().ip().safeParse(ipAddress);
    return parseResult.success;
  }
  return false;
};

export const validateLink = (url: string): boolean => {
  // A valid link is either a well formed web address, a relative address (within the same domain),
  // or one of a select few kinds of data addresses.
  if (!url || url === "about:blank") {
    return true;
  }

  url = url.trim();

  const safeUrlMatch = matchSafeUrl(url);
  const dataUrlMatch = url.match(DATA_URL_PATTERN);

  if (safeUrlMatch?.hasDomainWithoutProtocol) {
    // we reject these, because we expect front-end to have
    // already performed transform to add protocol in this case.
    return false;
  }
  return !!(safeUrlMatch.isMatch || dataUrlMatch);
};
