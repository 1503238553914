import "zod-openapi/extend";

import { z } from "zod";
import {
  BlockBaseSchema,
  ComponentBlockStyleSchema,
} from "block-system/types/base";
import { blockType } from "./constants";
import { BlockIdPlaceholder } from "utils/regex";
import { TableBlockSchema } from "../Table/schema";
import { MediaBlockSchema } from "../Media/schema";
import { KanbanBlockSchema } from "../Kanban/schema";
import {
  HorizontalAlignmentSchema,
  VerticalAlignmentSchema,
} from "../__shared__/components/AlignmentField/schema";
import { MarkdownTextBlockSchema } from "../MarkdownText/schema";
import { EmbedBlockSchema } from "../Embed/schema";
import { ChatbotBlockSchema } from "../Chatbot/types";
import { FormBlockSchema } from "../Form/Form/schema";
import { LinksBlockSchema } from "../Links/Links/schema";
import { ButtonBlockSchema } from "../Button/schema";
import { ChecklistBlockSchema } from "block-system/blocks/Checklist/schema";
import { StripePaymentBlockSchema } from "../StripePayment/schema";

export const MAX_COLUMN_COUNT = 3;
export const DEFAULT_ITEM_VERTICAL_ALIGNMENT = "center";

export const LayoutBlockConfigSchema = z.object({
  id: z.string().cuid().or(z.string().regex(BlockIdPlaceholder)).optional(),
  title: z.string().optional(),
  columnCount: z
    .number()
    .int()
    .min(2)
    .max(MAX_COLUMN_COUNT)
    .default(MAX_COLUMN_COUNT)
    .openapi({ effectType: "input" }),
  itemVerticalAlignments: z
    .array(VerticalAlignmentSchema)
    .max(3)
    .min(2)
    .default([
      DEFAULT_ITEM_VERTICAL_ALIGNMENT,
      DEFAULT_ITEM_VERTICAL_ALIGNMENT,
      DEFAULT_ITEM_VERTICAL_ALIGNMENT,
    ])
    .openapi({ effectType: "input" }),
  ordering: z.record(z.string().cuid(), z.number()),
  style: ComponentBlockStyleSchema.default({
    alignment: "center",
    width: "wide",
  }).openapi({ effectType: "input" }),
});

export const LayoutAllowedItemSchema = z.discriminatedUnion("type", [
  TableBlockSchema,
  MediaBlockSchema,
  KanbanBlockSchema,
  MarkdownTextBlockSchema,
  EmbedBlockSchema,
  ChatbotBlockSchema,
  FormBlockSchema,
  LinksBlockSchema,
  ButtonBlockSchema,
  ChecklistBlockSchema,
  StripePaymentBlockSchema,
]);
export const LayoutAllowedItemsSchema = LayoutAllowedItemSchema.array()
  .max(3, "Maximum 3 items allowed")
  .default([])
  .openapi({ effectType: "input" });

export type LayoutAllowedItem = z.infer<typeof LayoutAllowedItemSchema>;
export type LayoutAllowedItems = z.infer<typeof LayoutAllowedItemsSchema>;
export type LayoutAllowedItemTypes = LayoutAllowedItems[0]["type"];

export const LayoutBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: LayoutBlockConfigSchema,
  children: LayoutAllowedItemsSchema,
}).openapi({ ref: "LayoutBlock" });

export type LayoutItem = {
  name: string;
  block: LayoutAllowedItems[0] | null;
  index: number;
  order: number;
};
