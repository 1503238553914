import "zod-openapi/extend";

import { z } from "zod";
import { BlockBaseSchema } from "block-system/types/base";
import { blockType } from "./constants";
import { WidthSchema } from "../__shared__/components/WidthField/schema";
import { BlockIdPlaceholder } from "utils/regex";
import { sanitize } from "isomorphic-dompurify";

export const EmbedBlockConfigSchema = z.object({
  id: z.string().cuid().or(z.string().regex(BlockIdPlaceholder)).optional(),
  title: z.string(),
  width: WidthSchema.optional()
    .default("medium")
    .openapi({ effectType: "input" }),
  /**
   * The Zapier Design System does not have an input of type number.
   * Given that, we have to use a string-based input and parse it to a number.
   */
  height: z
    .string()
    .optional()
    .default("350")
    .openapi({ effectType: "input" })
    .superRefine((value, ctx) => {
      const parsedValue = Number(value);

      if (isNaN(parsedValue)) {
        return ctx.addIssue({
          message: "Not a number",
          code: z.ZodIssueCode.custom,
        });
      }

      if (!Number.isInteger(parsedValue)) {
        return ctx.addIssue({
          message: "Must be a whole number",
          code: z.ZodIssueCode.custom,
        });
      }

      if (parsedValue < 100 || parsedValue > 1000) {
        return ctx.addIssue({
          message: "Must be between 100 and 1000",
          code: z.ZodIssueCode.custom,
        });
      }
    }),

  /**
   * We have tried to use the `z.discriminatedUnion` here,
   * but found it too hard to work with in the "Editor" component.
   */
  source: z
    .enum(["code", "url"])
    .default("code")
    .openapi({ effectType: "input" }),

  code: z
    .string()
    .transform((value, ctx) => {
      if (!value) {
        return value;
      }

      const errorMessage = validateHTMLCode(value);
      if (errorMessage) {
        ctx.addIssue({
          message: errorMessage,
          code: z.ZodIssueCode.custom,
        });

        return z.NEVER;
      }

      return sanitize(value, {
        IN_PLACE: true,
        ALLOWED_TAGS: ["iframe"],
      });
    })
    .optional()
    /**
     * This is required so that we can use the `.transform`.
     * Otherwise, generating the openapi spec will fail.
     */
    .openapi({ type: "string" }),

  url: z
    .string()
    .superRefine((value, ctx) => {
      if (!value) {
        return z.NEVER;
      }

      const url = safeParseUrl(value);
      if (!url) {
        return ctx.addIssue({
          message: "Invalid URL",
          code: z.ZodIssueCode.custom,
        });
      }

      const errorMessage = validateUrl(url);
      if (errorMessage) {
        return ctx.addIssue({
          message: errorMessage,
          code: z.ZodIssueCode.custom,
        });
      }

      return z.NEVER;
    })
    .optional(),
});

export const EmbedBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: EmbedBlockConfigSchema,
}).openapi({ ref: "EmbedBlock" });

function validateHTMLCode(htmlCode: string) {
  const sanitizedValue = sanitize(htmlCode, {
    ALLOWED_TAGS: ["iframe"],
    RETURN_DOM_FRAGMENT: true,
    IN_PLACE: true,
  });

  if (sanitizedValue.children.length === 0) {
    return "Invalid HTML";
  }

  const iFrameElement = sanitizedValue.children[0] as HTMLIFrameElement;
  const url = safeParseUrl(iFrameElement.src);
  if (!url) {
    return "Invalid `src` attribute";
  }

  return validateUrl(url);
}

function validateUrl(url: URL) {
  if (url.protocol !== "https:") {
    return "Only HTTPS addresses are allowed";
  }

  const disallowedHostnames = [
    "zapier-staging.app",
    "zapier-staging.com",
    "zapier.com",
    "zapier.app",
  ];
  const isDisallowedHostname = disallowedHostnames.some(
    (disallowedHostname) => {
      return url.hostname.includes(disallowedHostname);
    }
  );
  if (isDisallowedHostname) {
    return "Disallowed address";
  }

  return null;
}

function safeParseUrl(url: string) {
  try {
    return new URL(url);
  } catch {
    return null;
  }
}
