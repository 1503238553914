import "zod-openapi/extend";

import { z } from "zod";
import { BlockIdPlaceholder } from "utils/regex";
import { BlockBaseSchema, FilterSchema } from "block-system/types/base";
import { blockType } from "./constants";
import { WidthSchema } from "../__shared__/components/WidthField/schema";
import { HorizontalAlignmentSchema } from "../__shared__/components/AlignmentField/schema";
import { RecordOrderSchema } from "server/schemas/table-records";

export const KanbanColumnSchema = z.object({
  value: z.any(),
  label: z.string(),
});

export const KanbanBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: z.object({
    id: z.string().cuid().or(z.string().regex(BlockIdPlaceholder)).optional(),
    title: z.string().optional(),
    tableId: z.string().optional(),
    groupFieldId: z.number().or(z.string()).optional(),
    cardTitleFieldId: z.number().or(z.string()).optional(),
    cardDescriptionFieldId: z.number().or(z.string()).optional(),
    cardDetailFields: z.array(z.number().or(z.string())).optional(),
    selectedColumns: KanbanColumnSchema.array().optional(),
    canCreateCards: z.boolean().default(false).openapi({ effectType: "input" }),
    newCardButtonCopy: z.string().optional(),
    canUpdateCards: z.boolean().default(false).openapi({ effectType: "input" }),
    canDeleteCards: z.boolean().default(false).openapi({ effectType: "input" }),
    canMoveCards: z.boolean().default(true).openapi({ effectType: "input" }),
    canMoveColumns: z.boolean().default(false).openapi({ effectType: "input" }),
    style: z
      .object({
        width: WidthSchema.optional(),
        alignment: HorizontalAlignmentSchema.optional(),
      })
      .optional(),
    filters: z.array(FilterSchema).optional(),
    orders: z
      .array(RecordOrderSchema)
      .optional()
      .openapi({ effectType: "input" }),
  }),
  stateId: z.string().cuid().optional(),
}).openapi({ ref: "KanbanBlock" });
