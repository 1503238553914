import "zod-openapi/extend";

import { z } from "zod";
import { BlockBaseSchema } from "block-system/types/base";
import { blockType } from "./constants";
import { BlockIdPlaceholder } from "utils/regex";

export const LoginFormBlockConfigSchema = z.object({
  id: z.string().cuid().or(z.string().regex(BlockIdPlaceholder)).optional(),
});

export const LoginFormBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: LoginFormBlockConfigSchema,
}).openapi({ ref: "LoginFormBlock" });
