import "zod-openapi/extend";

import { z } from "zod";
import {
  BlockBaseSchema,
  ComponentBlockStyleSchema,
} from "block-system/types/base";
import { blockType } from "./constants";
import { BlockIdPlaceholder } from "utils/regex";
import {
  NavigateActionSchema,
  OpenExternalUrlActionSchema,
  NotificationActionSchema,
} from "block-system/types/common";

const oneDollarInCents = 100;

export const StripeActionSchema = z.discriminatedUnion("type", [
  NavigateActionSchema,
  OpenExternalUrlActionSchema,
  NotificationActionSchema,
]);

export const StripeActionsSchema = StripeActionSchema.array().max(2);

export type StripeAction = z.infer<typeof StripeActionSchema>;
export type StripeActions = z.infer<typeof StripeActionsSchema>;
export type AllowedStripeActionType = StripeAction["type"];

export const StripePaymentBlockConfigSchema = z.object({
  id: z.string().cuid().or(z.string().regex(BlockIdPlaceholder)).optional(),

  title: z
    .string()
    .default("My product")
    .openapi({ effectType: "input" })
    .superRefine((value, ctx) => {
      const trimmedValue = value.trim();
      if (trimmedValue.length <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Title is required",
        });

        return z.NEVER;
      }

      /**
       * 100 character limit is arbitrary.
       */
      if (trimmedValue.length > 100) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "The title can't have more than 100 characters",
        });

        return z.NEVER;
      }
    }),

  description: z.string().optional(),

  buttonLabel: z
    .string()
    .default("Place an order")
    .openapi({ effectType: "input" })
    .superRefine((value, ctx) => {
      const trimmedValue = value.trim();
      if (trimmedValue.length <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Button label is required",
        });

        return z.NEVER;
      }
      /**
       * 100 character limit is arbitrary.
       */
      if (trimmedValue.length > 100) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "The button label can't have more than 100 characters",
        });

        return z.NEVER;
      }
    }),

  currency: z
    .enum(["USD", "EUR", "CAD"])
    .default("USD")
    .openapi({ effectType: "input" }),

  value: z.number().default(oneDollarInCents).openapi({ effectType: "input" }),

  quantityLimit: z
    .number()
    .min(1, "The minimum quantity limit is 1")
    .default(5)
    .openapi({ effectType: "input" }),

  emoji: z.string().optional(),

  payment: z
    .discriminatedUnion("mode", [
      z.object({
        mode: z.literal("payment"),
        interval: z.null(),
      }),
      z.object({
        mode: z.literal("subscription"),
        interval: z.enum(["week", "month", "year"], {
          message: "Subscription interval is required for subscriptions",
        }),
      }),
    ])
    .default({ mode: "payment", interval: null })
    .openapi({ effectType: "input" }),

  style: ComponentBlockStyleSchema.default({
    alignment: "center",
    width: "narrow",
  }).openapi({ effectType: "input" }),
  triggers: StripeActionsSchema.default([]).openapi({ effectType: "input" }),
});

export const StripePaymentBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: StripePaymentBlockConfigSchema,
}).openapi({ ref: "StripePaymentBlock" });
