import { BlockType } from "@prisma/client";

export const blockType = "markdown-text-block";
export const blockTypeDbName = BlockType.MarkdownText;

export const blockTypeToName = {
  [blockType]: "Text",
} as const;

export const blockNameToType = {
  [blockTypeDbName]: blockType,
} as const;
